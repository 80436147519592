<template>
    <div>
        <div ref="cratefile" id="cratefile" class="cratefile"></div>
    </div>
</template>
<script>
import WebOfficeSDK from '@/utils/web-office-sdk-v1.1.19.es.js'
import { Store } from '@lk/cache'
  export default {
    data() {
      return {
        url: null
      };
    },
    methods: {
        closeLoding(){
          this.$emit('createOver');
        },
        init(url){
			let linkUrls = url
            this.$nextTick(()=>{
				const onHyperLinkOpen = ({
					linkUrl, // 跳转 url
				}) => {
					// 自身业务处理
					jssdk.iframe.src=linkUrl
					this.$refs['cratefile'].appendChild(jssdk.iframe)
				};
				const jssdk = WebOfficeSDK.config({
					mode:'nomal',
					commonOption:{
						isIframeViewFullscreen:true,
						isBrowserViewFullscreen:true
					},
					commandBars:[
						{
							cmbId:'MoreMenus',
							attributes: {
								visible: false, // 隐藏组件
							}
						},
						{
							cmbId:'HeaderHistoryMenuBtn',
							attributes: {
								visible: false, // 隐藏组件
							}
						},{
							cmbId:'PreviewDownload',
							attributes: {
								visible: false, // 隐藏组件
							}
						}
					],
					onToast:({
						msg,
						action
					})=>{
						
					},
					url:linkUrls,
					onHyperLinkOpen,
					mount: this.$refs['cratefile'],
				});
				this.url = url;
				this.$refs['cratefile'].appendChild(jssdk.iframe)
				jssdk.setToken({
					token:Store.get("access_token"), // 根据自身的业务需求，通过异步请求或者模板输出的方式，取得 token
					timeout: 10 * 60 * 1000, // token 超时时间，可配合 refreshToken 配置函数使用，在超时前调用 refreshToken 重新刷新 token
				});
				
			});
        }
    },
    mounted(){
        this.init(this.$route.query.urls)
    }
  };
</script>
<style lang="less" scoped>
.cratefile {
    height: 100vh;
}
.component-notice{
	display: none;
}
</style>